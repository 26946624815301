<template>
  <div>
    <BreadCrumb :items="breadCrumb" />
    <EditQuizForm :key="(quiz ? quiz.id : 0)" :book_id="book_id" :value="quiz" :busy="quizLoading" />
  </div>
</template>

<script>
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import EditQuizForm from '@/components/form/EditQuizForm.vue';
import getQuizMixin from '@/mixins/getquiz';

export default {
  mixins: [getQuizMixin],
  components: { BreadCrumb, EditQuizForm },

  data() {
    return {
      book_id: this.$route.params.book_id,
      quiz_id: this.$route.params.quiz_id,
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books List', to: { name: 'bke-book-list' } },
        { text: this.book_id, to: { name: 'bke-book-view', params: { book_id: this.book_id } } },
        { text: 'Quizzes List', to: { name: 'quiz-list', params: { book_id: this.book_id } } },
        { text: this.quiz_id, to: { name: 'quiz-view', params: { book_id: this.book_id, quiz_id: this.quiz_id } } },
        { text: 'Edit', active: true },
      ];
    },
  },

};
</script>
